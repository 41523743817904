<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-title>
            School Years / Sessions
            <v-spacer></v-spacer>
            <v-btn fab small color="primary" @click="createItem()" v-if="canEditSettings">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-list shaped>
            <v-list-item-group color="primary" v-model="selectedSemester">
              <v-list-item v-for="(semester, i) in semesters" :key="i" @click="editItem(semester, i)">
                <v-list-item-icon>
                  <v-icon :color="colors[semester.color].color">mdi-checkbox-blank-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ semester.name }}</v-list-item-title>
                <v-icon v-if="semester.synced">mdi-sync</v-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="9">
        <div class="d-flex justify-space-between mb-2" v-if="showable">
          <v-btn
            @click="
              editedItem = {};
              baseFormValue = {};
              showable = false;
            "
          >
            Cancel
          </v-btn>
          <div>
            <v-btn
              class="mx-2"
              dark
              color="success"
              @click="saveItem()"
              v-show="!editedItem.locked && editedItem.name"
              v-if="canEditSettings && !isRoutingClient"
            >
              Save
            </v-btn>
            <v-btn
              class="mx-2"
              dark
              color="error"
              @click="deleteItem()"
              v-show="!editedItem.locked && editedItem.id"
              v-if="canEditSettings && !isRoutingClient"
            >
              Delete
            </v-btn>
          </div>
        </div>
        <v-card v-show="showable" class="mb-4">
          <v-form :readonly="!canEditSettings || editedItem.synced">
            <v-card-text>
              <v-container>
                <v-alert v-if="editedItem.synced" outlined type="info" text>
                  This Semester / School Year is synced with EZRouting. Any changes will need to be made there to be
                  synced here.
                </v-alert>
                <v-alert v-else-if="!editedItem.id && isRoutingClient" outlined type="info" text>
                  This Semester / School Year will only be avilable in EZActivityTrips. If you would like this Semester
                  / School Year to be available in EZRouting, please add it there first and it will be synced with
                  EZActivityTrips.
                </v-alert>
                <v-row dense>
                  <v-col cols="12" md="4">
                    <v-text-field v-model="editedItem.name" label="School Year / Session Name"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      v-model="editedItem.schoolYear"
                      :items="years"
                      item-text="label"
                      item-value="id"
                      label="School Year"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="4">
                    <date-picker
                      label="Starting Date"
                      v-model="editedItem.fromDate"
                      required
                      :readonly="!canEditSettings || editedItem.synced"
                    ></date-picker>
                  </v-col>
                  <v-col cols="12" md="4">
                    <date-picker
                      label="Ending Date"
                      v-model="editedItem.toDate"
                      required
                      :readonly="!canEditSettings || editedItem.synced"
                    ></date-picker>
                  </v-col>
                  <v-col cols="12" md="4" v-if="editedItem.id">
                    <v-select
                      v-model="editedItem.color"
                      :items="colors"
                      item-text="label"
                      item-value="index"
                      label="Colors"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_SEMESTERS, SAVE_SEMESTER, DELETE_SEMESTER } from '@/store/modules/Semester/actions';
import DatePicker from '@/components/DatePicker.vue';
import promptBeforeLeave from '@/mixins/promptBeforeLeave';

export default {
  name: 'Semesters',
  inject: ['eventHub'],
  components: { DatePicker },
  data() {
    return {
      showable: false,
      editedItem: {},
      semesters: [],
      years: [],
      colors: [
        { index: 0, label: 'Pink', color: 'pink' },
        { index: 1, label: 'Green', color: 'green' },
        { index: 2, label: 'Blue', color: 'blue' },
        { index: 3, label: 'Purple', color: 'deep-purple' },
        { index: 4, label: 'Orange', color: 'orange' },
      ],
      selectedSemester: null,
    };
  },
  computed: {
    ...mapGetters('user', ['me', 'canEditSettings']),
    ...mapGetters('app', ['isRoutingClient']),
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('semester', [GET_SEMESTERS, SAVE_SEMESTER, DELETE_SEMESTER]),
    async fetchItems() {
      this.semesters = await this.getSemesters();

      let startyear = new Date().getFullYear() - 5;
      if (startyear > this.semesters[0].schoolYear) startyear = this.semesters[0].schoolYear;
      const endyear = new Date().getFullYear() + 5;
      for (let year = startyear; year <= endyear; year++) this.years.push({ id: year, label: `${year} ~ ${year + 1}` });

      this.colorItems;
    },
    createItem() {
      this.editedItem = {};
      this.baseFormValue = {};
      this.showable = true;
    },
    async editItem(item, index = null) {
      const curr = this.selectedSemester;
      if (index === null) index = this.selectedSemester;
      this.showable = true;
      const willProceed = await this.performPromptBeforeLeave();
      if (willProceed) {
        this.editedItem = item;
        this.baseFormValue = { ...this.editedItem };
        this.selectedSemester = index;
        return;
      }

      this.selectedSemester = curr;
    },
    async saveItem() {
      try {
        const r = await this.saveSemester(this.editedItem);
        if (r && r.id) {
          this.$myalert.success('School Year / Session saved');
          await this.fetchItems();
          this.baseFormValue = { ...this.editedItem };
          this.editItem(this.semesters.find((e) => e.id == r.id));
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async deleteItem() {
      if (this.editedItem.id) {
        const ok = await this.$myconfirm('Are you sure you want to delete this School Year / Session?');
        if (ok) {
          try {
            const r = await this.deleteSemester(this.editedItem.id);
            if (r.done) {
              this.$myalert.success('School Year / Session deleted');
              await this.fetchItems();
            }
          } catch (e) {
            this.$myalert.error(e.message);
          }
        }
      }
      this.editedItem = {};
      this.showable = false;
    },
  },
  watch: {
    editedItem: {
      handler(value) {
        this.latestFormValue = { ...value };
      },
      deep: true,
    },
  },
  mixins: [promptBeforeLeave],
};
</script>

<style lang="scss">
.table-action {
  margin-left: 10px;
}
</style>
